<template>
  <v-row class="listing-footer">
    <v-col md="4" class="margin-auto">
      <v-skeleton-loader
        type="text"
        class="custom-skeleton height-30px"
        v-if="dataLoading"
      ></v-skeleton-loader>
      <p v-else class="m-0 listing-footer-count">
        Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries
      </p>
    </v-col>
    <v-col md="8" class="right-pagination">
      <v-skeleton-loader
        type="text"
        class="custom-skeleton height-30px"
        v-if="dataLoading"
      ></v-skeleton-loader>
      <v-pagination
        v-else
        :total-visible="7"
        v-model="currentModelPage"
        :length="totalPages"
        color="cyan"
        :disabled="dataLoading"
        v-on:input="updatePagination"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { PaginationEventBus } from "@/core/lib/pagination.lib";

export default {
  props: {
    dataLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    showingFrom: {
      type: Number,
    },
    showingTo: {
      type: Number,
    },
    totalRows: {
      type: Number,
    },
  },
  data: () => {
    return {
      currentModelPage: 0,
    };
  },
  methods: {
    updatePagination() {
      this.$router.replace({ query: { page: this.currentModelPage } });
      PaginationEventBus.$emit("update:pagination", this.currentModelPage);
    },
  },
  mounted() {
    this.currentModelPage = this.currentPage;
    if (this.$route.query.page) {
      this.currentModelPage = Number(this.$route.query.page);
    }
  },
};
</script>
